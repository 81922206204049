export default function useTagList() {
  const columns = [
    { key: 'title', sortable: true },
    { key: 'slug', sortable: true },
    { key: 'description', sortable: true },
    { key: 'Action', sortable: false },
    // { key: 'stock', sortable: true },
  ];

  return {
    columns,
  };
}
